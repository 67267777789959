<template>
  <div class="text-center">
    <v-dialog v-model="show" width="500" persistent>
      <v-card>
        <v-card-title>ตั้งค่าวันหมดอายุไฟล์ <v-spacer></v-spacer>
          <v-icon class="pr-2" :color="color.theme">mdi-file-clock</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-list>
            <v-list-item>
              <v-list-item-icon class="pt-2">แก้ไขวันหมดอายุ :</v-list-item-icon>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2"
                        prepend-icon="mdi-calendar"
                        label="วันที่ที่ต้องการแก้ไข"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        class="pt-3"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date2"  @input="menu2 = false" @change="fn_changedate(date2)"></v-date-picker>
                  </v-menu>
            </v-list-item>
            <v-list-item class="pl-8">
              <v-list-item-icon>
                วันหมดอายุ :
              </v-list-item-icon>
              <v-chip color="error"> {{ fn_showdatetime(filedata.expired_inbox) }}</v-chip>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('closedialog') , date2=''">
            {{ $t("uploadfolder.buttonclose") }}
          </v-btn>
          <v-btn dark @click="fn_edit_data()" :color="color.theme">
            {{ $t("checkpassword.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      date2:'',
      menu2:false,
      changedates:''
    };
  },
  props: ["show", "filedata"],
  methods: {
    //   แปลงค่า response.expired_inbox จาก  YYYYMMDDHHMMSS เป็น YYYY-MM-DD โชว์ใน chip 
    fn_showdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined || _datetime === null) {
        return "ยังไม่มีการตั้งค่าวันหมดอายุ";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];

        return   dateyear + "-" + datemonth + "-" + dateday;
      }
    }, 
    // แปลงค่า v-model จาก YYYY-MM-DD เป็น YYYYMMDDHHMMSS 
    fn_changedate(_date){
       let dateyear = _date.split("")[0] + _date.split("")[1] + _date.split("")[2] +_date.split("")[3]
       let datemonth = _date.split("")[5] + _date.split("")[6]
       let datedays = _date.split("")[8] + _date.split("")[9]
      //  เก็บค่าที่แปลงใส่ตัวแปล changedates และนำไปใช้ในการยิง api 
       this.changedates = dateyear+datemonth+datedays+"000000"
       return(this.changedates)
    },
    // ฟังก์ชันนี้ ยิง api เพื่อทำการแก้ไขหรืออัปเดต
   async fn_edit_data(){
       let payload ={
         expired_time:this.changedates,
         inbox_id:[this.filedata.inbox_id],
         status:"set_expired",
         sender_id:this.dataAccountActive.business_info.business_id
       }
       let auth = await gbfGenerate.generateToken();
       await this.axios
             .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_expired_inbox", payload,{headers: { Authorization: auth.code },})
             .then((response) =>{
                 console.log("response",response);
                 if(response.data.status === "OK"){
                  // เครียค่าและ emitค่ากลับไปยังหน้า fileexpired-outbox เพื่อเรียกใช้ฟังก์ชันเรียกไฟล์(Refresh)
                   this.$emit('closedialogandrefresh')
                   this.date2 =''
                   this.menu2 =false
                   Toast.fire({
                      icon: "success",
                      title: "แก้ไขสำเร็จ",
                    });
                 }
             })
    }
  },
  computed: {
    ...mapState([
      "color",
      "status_permission_outbox",
      "status_permission_inbox",
      "feature_status",
      "role_level",
      "storage_usage",
      "storage_usage_biz",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
    }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
  },
};
</script>
